import { defineStore } from 'pinia'
export default defineStore('user', {
	state: () => ({
		name: ''
	}),
	actions: {
		test(val) {
			this.name = val
		}
	},
	persist: {
    enabled: true,
    strategies: [
      {
        storage: sessionStorage
      }
    ],
  }
})