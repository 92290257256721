import { defineStore } from 'pinia'
export default defineStore('user', {
	state: () => ({
		step: 0,
		finished: false,
		complete: false
	}),
	actions: {
		add() {
			this.step+=1
		},
		doFinished() {
			this.finished = true
		},
		doComplete() {
			this.complete = true
		}
	},
	persist: {
    enabled: false,
    strategies: [
      {
        storage: sessionStorage
      }
    ],
  }
})